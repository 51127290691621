// Header Animation
// ---------------------------------------------------------------------------------------------
class HeadingAnimation extends HTMLElement {
  constructor() {
    super();
    // "Animates" a headline up beneath the logo

    // Wrap this header animation element around one element that you wish to "animate"
    // It´s not a real animation but a scale transform

    // The transformation takes this element from it´s original position
    // within the flow and moves it up beneath the logo in the header
    // It works both ways so the text "animates" back down in place on scroll up to original position

    // Accepts attributes:
    // - data-animate: {Attribute} Specify if the header should animate or not (It takes no value) (optional)
    // - data-stick-on-load: {Attribute} Show element underneath logo on load (It takes no value) (optional) (should be default for collection page)
    // - data-percentage-on-header: {Attribute = integer} The percentage where the top of the animation element should stick (takes a value) values: Integer (required)

    // Usage:
    // <heading-animation data-percentage-on-header="50" data-animate data-stick-on-load> <Element></Element> </heading-animation>

    // only animate if animate is selected for this element, it is the first animation element and if sticky header is selected
    this.stickyHeader = document.querySelector('sticky-header');
    this.firstHeaderAnimation = document.querySelector('heading-animation') == this;
    this.allowAnimation = this.hasAttribute('data-animate') && this.stickyHeader != null && this.firstHeaderAnimation;

    if (this.allowAnimation) {
      // Settings
      this.maxSize = this.offsetHeight;
      this.minSize = this.getAttribute('data-min-size') ? (this.stickyHeader.offsetHeight / 100) * this.getAttribute('data-min-size') : 20;

      // Calculate scaleMin percentage of font sizes
      this.scaleMax = 1;
      this.scaleMin = this.minSize / this.maxSize;

      // Make sure to get the correct computed values after load
      window.onload = function () {
        this.maxSize = parseFloat(window.getComputedStyle(this).getPropertyValue('height'));
        this.minSize = this.getAttribute('data-min-size') ? (this.stickyHeader.offsetHeight / 100) * this.getAttribute('data-min-size') : 20;
        this.scaleMin = this.minSize / this.maxSize;
      }.bind(this);

      // Elements
      this.animateContainer = this;

      // Positions
      this.fixedPosition = this.getFixedPositionOnHeader();
      this.originalRelativePosition = this.getOffset(this.animateContainer).documentTop;

      //this.onLoadScrollY = window.scrollY;
      this.oldScrollY = window.scrollY;
      let distanceFromTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      if (this.placeHeaderOnCollections()) {
        // If header has been placed based on collection logic, then skip the further default placements
        return;
      }

      if (distanceFromTop == 0 || distanceFromTop < this.originalRelativePosition + this.fixedPosition) {
        // Only add animation if page scroll position is in top of the page on load
        window.onload = function () {
            this.addScrollEventListener();
        }.bind(this);
      } else {
        // If page is not scrolled in top then set the title where it should be on header
        this.placeOnHeader();
      }
    }
  }

  getFixedPositionOnHeader() {
    // Get fixed position relative to entire page based on where it should be on the header
    let header = document.querySelector('sticky-header') || document.querySelector('header');
    // Calculate position from bottom of header
    let percentageOnHeader = parseInt(this.animateContainer.getAttribute('data-percentage-on-header'));
    let fixedPosition = (header.getBoundingClientRect().height * percentageOnHeader) / 100;
    return fixedPosition;
  }

  placeHeaderOnCollections() {
    // If on a collection page and not on the first pagination page, the title should be placed underneath the logo as default
    // For this we need to know if the page parameter excist in the url and is above 1
    // On collection pages the element should have the data attribute 'data-stick-on-load' as default

    const url = new URL(window.location.href);

    // If page is a collection
    if (url.pathname.includes('collection')) {
      const page = url.searchParams.get('page');
      if (!page || page == 1) {
        // If page number is one or is absent then animate
        this.animateContainer.removeAttribute('data-stick-on-load');
        this.originalRelativePosition = this.getOffset(this.animateContainer).documentTop;
        this.addScrollEventListener();
      } else {
        // If page number higher than one then place under logo as default
        this.placeOnHeader();
      }
      return true;
    }
    return false;
  }

  addScrollEventListener() {
    window.addEventListener('scroll', this.animate.bind(this));
  }

  animate() {
    this.setScrollDirection();

    // If sticky scroll and scroll down or in top of page
    if ((this.stickyHeader.getAttribute('data-sticky-type') == 'on-scroll-up' && this.scrollDirection == 'down') || window.scrollY < 100) {
      this.followPositionOnHeader();
    }

    // Distance to top of screen from top of element
    let rectTop = this.getOffset(this.animateContainer).screenTop;

    // The animation should start from the top and stop when criterias are met
    let stopAnimate = rectTop <= this.fixedPosition && this.originalRelativePosition <= window.scrollY + this.fixedPosition - rectTop;
    if (this.originalRelativePosition <= window.scrollY + this.fixedPosition) {
      // If the page is scrolled further down than the scroll position where it should stick
      stopAnimate = this.originalRelativePosition <= window.scrollY + this.fixedPosition;
    }

    const spacer = this.animateContainer.nextElementSibling;

    if (!stopAnimate) {
      // When animate
      this.animateContainer.classList.remove('stop-animate');
      this.animateContainer.style.top = `${this.fixedPosition}px`;
      this.animateContainer.style.transform = `scale(${this.scale(this.animateContainer, this.fixedPosition)})`;

      /* Hide dublicate heading */
      if(this.dublicate){
        this.dublicate.style.display = 'none';
      }
      this.style.visibility = 'visible';
      spacer.classList.add('tw-hidden');

    } else if (stopAnimate) {
      // If criteria for start animate has been met but also stop has been met
      // When stop animate
      this.animateContainer.classList.add('stop-animate');
      this.animateContainer.style.top = `${this.fixedPosition}px`;
      this.animateContainer.style.transform = `scale(${this.scaleMin})`;
      this.appendDublicateInHeader()
      this.style.visibility = 'hidden'; // Hide this so heading is not displayed twice
      spacer.classList.remove('tw-hidden');
    }
  }

  placeOnHeader() {

    // When stop animate
    this.animateContainer.style.transition = `0s`;
    this.animateContainer.classList.add('stop-animate');
    this.animateContainer.style.top = `${this.fixedPosition}px`;
    this.animateContainer.style.left = '0px';
    this.animateContainer.style.transform = `scale(${this.scaleMin})`;
    this.addScrollEventListener();
  }

  scale(element, fixedPosition) {
    // Scale the animated element based on the relation between max and min and the distance it needs to animate over
    let rectTop = this.getOffset(element).screenTop;
    let relativePos = this.getOffset(element).documentTop;
    relativePos = this.originalRelativePosition;
    let scaleValue = ((this.scaleMax - this.scaleMin) / (relativePos - fixedPosition)) * (rectTop - fixedPosition) + this.scaleMin;

    // Return calculated scale value if it´s between 0 and 1 else return 1
    return scaleValue >= 0 && scaleValue <= 1 ? scaleValue: 1;
  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      documentTop: rect.top + window.scrollY,
      screenTop: rect.top,
      screenLeft: rect.left,
    };
  }

  followPositionOnHeader() {
    // When the header moves based on header sticky setting
    // Move the animated element with it
    this.animateContainer.style.transition = `0s`;
    this.fixedPosition = this.getFixedPositionOnHeader();
    this.animateContainer.style.top = `${this.fixedPosition}px`;
  }

  setScrollDirection() {
    if (this.oldScrollY < window.scrollY) {
      this.scrollDirection = 'down';
    } else {
      this.scrollDirection = 'up';
    }
    this.oldScrollY = window.scrollY;
  }

  appendDublicateInHeader() {
    // Create a dublicate of the element to be in the header
    // This is to make sure that it shows on top, since the animated heading would be below the header background color
    if(this.stickyHeader.querySelector('.header') && this.stickyHeader.querySelector('.header').querySelector('.heading-animation-dublicate')){
      this.dublicate.style.display = 'block';
    } else {
      if (!this.dublicate) {
        this.dublicate = this.cloneNode(true); // Create a dublicate of this
      }

      // Style the dublicate to match
      this.dublicate.classList.add('heading-animation-dublicate');
      this.dublicate.style.textTransform = 'capitalize';
      this.dublicate.style.textAlign = 'center';
      this.dublicate.style.transition = '0.2s';

      // Append the dublicate to the header
      this.stickyHeader.querySelector('.header').appendChild(this.dublicate);
    }



  }
}
customElements.define('heading-animation', HeadingAnimation);

// Logo Animation
// ---------------------------------------------------------------------------------------------
class LogoAnimation extends HeadingAnimation {
  // "Animates" a logo up on the header

  // Wrap this logo animation element around one element that you wish to "animate"
  // It´s not a real animation but a scale transform

  // The transformation takes this element from it´s original position
  // within the flow and moves it up beneath the logo in the header
  // It only goes up an does not come down again (different from heading-animation)

  // Accepts attributes:
  // - data-animate: {Attribute} Specify if the header should animate or not (It takes no value) (optional)
  // - data-stick-on-load: {Attribute} Show element underneath logo on load (It takes no value) (optional) (should be default for collection page)
  // - data-percentage-on-header: {Attribute = integer} The percentage where the top of the animation element should stick (takes a value) values: Integer (required)

  // Usage:
  // <heading-animation data-percentage-on-header="50" data-animate data-stick-on-load> <Element></Element> </heading-animation>

  constructor() {
    super();
    // Criteria for adding the logo animation
    this.firstLogoAnimation = document.querySelector('logo-animation') == this; // Only animate if the logo animation is the first on the page
    this.loadTopOfPage = window.scrollY < this.getOffset(this).documentTop; // Returns true if the scroll position on load is less than the distance to the logo (where it gets stuck to the header)

    this.allowAnimation = this.hasAttribute('data-animate') && this.stickyHeader != null && this.firstLogoAnimation && this.loadTopOfPage;

    if (window.pageYOffset == 0) {
      if (this.allowAnimation) {
        this.stickyHeader.setAttribute('data-hide-logo', true); // Set attribute to show original logo
        // Add fadein to big logo
        this.style.transition = 'all 0.2s';
        this.classList.remove('tw-opacity-0');

        // Settings
        this.maxSize = this.offsetHeight;
        this.minSize = this.getAttribute('data-min-size')? this.stickyHeader.offsetHeight / 100 * this.getAttribute('data-min-size') : 20;
        this.maxWidth = this.offsetWidth;
        this.maxHeight = this.offsetHeight;

        // Calculate scaleMin percentage of font sizes
        this.scaleMax = 1;
        this.scaleMin = this.minSize / this.maxSize;


        // Make sure to get the computed values after load
        window.onload = function() {
          this.maxSize = parseFloat(window.getComputedStyle(this).getPropertyValue('height'));
          this.minSize = this.getAttribute('data-min-size')? this.stickyHeader.offsetHeight / 100 * this.getAttribute('data-min-size') : 20;
          this.scaleMin = this.minSize / this.maxSize;
          this.maxWidth = parseFloat(window.getComputedStyle(this).getPropertyValue('width'));
          this.maxHeight = parseFloat(window.getComputedStyle(this).getPropertyValue('height'));
          this.style.maxWidth = `${this.maxWidth}px`;
        }.bind(this);

        // Elements
        this.animateContainer = this;

        // Positions
        // Calculate fixed position on header
        this.fixedPosition = this.getFixedPositionOnHeader();
        this.originalRelativePosition = this.getOffset(this.animateContainer).documentTop;
        this.largestScrollY = 0;
        this.isInPlace = false;

        this.onLoadScrollY = window.scrollY;

        if (this.onLoadScrollY == 0 || this.onLoadScrollY < this.originalRelativePosition + this.fixedPosition) {
          // Only add animation if page scroll position is in top of the page on load
          this.addScrollEventListener();
        } else {
          // If page is not scrolled in top then set the title where it should be on header
          this.placeOnHeader().bind(this);
        }
      } else {
        // If animation is not allowed
        this.style.display = 'none'; // Hide big animation logo
      }
    }
  }

  animate() {
    // Set larges scroll so far to prevent increase on scroll up
    if (window.scrollY > this.largestScrollY) {
      this.largestScrollY = window.scrollY;
    }

    // If in top of page change fixed position to stay in same postion on header when announcement bar shows up
    if (window.scrollY < this.fixedPosition || window.scrollY < 100) {
      this.followPositionOnHeader();
    }

    // Distance to top of screen from top of element
    let rectTop = this.getOffset(this.animateContainer).screenTop;

    // The animation should start from the top and stop when criterias are met
    let stopAnimate = rectTop <= this.fixedPosition && this.originalRelativePosition <= window.scrollY + this.fixedPosition - rectTop +300;
    if (this.originalRelativePosition <= window.scrollY + this.fixedPosition) {
      // If the page is scrolled further down than the scroll position where it should stick
      stopAnimate = this.originalRelativePosition <= window.scrollY + this.fixedPosition + 140;
    }
    if (!stopAnimate && this.largestScrollY <= window.scrollY && !this.isInPlace) {
      // When animate
      this.animateContainer.classList.remove('stop-animate');
      this.animateContainer.style.top = `${this.fixedPosition}px`;
      this.animateContainer.style.transform = `scale(${this.scale(this.animateContainer, this.fixedPosition)})`;
      //this.nextElementSibling.style.height = '0px';
      this.animateContainer.style.left = '0px';
      this.animateContainer.style.width = `${this.maxWidth}px`;
      this.animateContainer.querySelector('.animation-logo__image').classList.remove('logo-animation--on-header')

    } else if (stopAnimate && !this.isInPlace) {
      // If criteria for start animate has been met but also stop has been met
      // When stop animate
      this.isInPlace = true;
      this.stickyHeader.setAttribute('data-hide-logo', false);
      this.animateContainer.setAttribute('data-hide-logo', true);
      this.animateContainer.classList.add('stop-animate');
      //this.nextElementSibling.style.height = `${this.maxHeight}px`;
      this.animateContainer.style.top = `${this.fixedPosition}px`;
      let logo = this.stickyHeader.querySelector('.header__heading-logo');
      this.animateContainer.style.left = `${this.getOffset(logo).screenLeft}px`;
      this.animateContainer.style.width = `${parseFloat(window.getComputedStyle(logo).getPropertyValue('width'))}px`;
      this.animateContainer.style.transform = `scale(1)`;
      this.animateContainer.querySelector('.animation-logo__image').classList.add('logo-animation--on-header')
    }
  }
}
customElements.define('logo-animation', LogoAnimation);
